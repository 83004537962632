@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body{
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #17181B;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent !important; 
  background-image: url("/public/images/arrow-up.svg") !important;
  background-repeat: no-repeat !important;
  background-position-x: 100% !important;
  background-position-y: 0px !important;
}

textarea{
  -webkit-appearance: none;
  -moz-appearance: none;
  resize: none;
}