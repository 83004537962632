.active{
    font-weight: 500;
    opacity: 1;
}

.button2{
    background-color: #1053F3;
    box-shadow: 0px 14px 29px rgba(16, 83, 243, 0.2);
    height: 48px;
    opacity: 1;
}

.active .button2{
    background-color: #929292;
    box-shadow: 0px 14px 29px 0px rgba(138, 138, 138, 0.20);

}

@media (max-width:1024px) {
        
}

.mobile .active{
    background-color:#1054f3af;
    color: white;
}