.about-header-text{
    color: #757575;
}


@media (max-width:1200px) {
    .img-cont{
        min-width: 370px;
        width: 370px;

    }
    .img-cont img{
        min-width: 370px;
        width: 370px;

    }
    
}

@media (max-width:376px) {
    .img-cont{
        min-width: 350px;
        width: 350px;

    }
    .img-cont img{
        min-width: 350px;
        width: 350px;

    }
    
}

.about-img-cont{
    width: 100%;
    height: 361px;
}


@media (min-width:640px) {
    .about-img-cont{
        width: 550px;
        height: 550px;

    }
}